@font-face {
  font-family: "WorkSans";
  src: url("/assets/fonts/work-sans-v18-latin-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "WorkSans";
//   src: url("/assets/fonts/work-sans-v18-latin-italic.woff2") format("woff2");
//   font-weight: 400;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: "WorkSans";
  src: url("/assets/fonts/work-sans-v18-latin-500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "WorkSans";
//   src: url("/assets/fonts/work-sans-v18-latin-500italic.woff2") format("woff2");
//   font-weight: 500;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: "WorkSans";
  src: url("/assets/fonts/work-sans-v18-latin-600.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "WorkSans";
//   src: url("/assets/fonts/work-sans-v18-latin-600italic.woff2") format("woff2");
//   font-weight: 600;
//   font-style: italic;
//   font-display: swap;
// }


@font-face {
  font-family: "ABCMarist";
  src: url("/assets/fonts/ABCMarist-Medium.woff") format("woff"), url("/assets/fonts/ABCMarist-Medium.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
