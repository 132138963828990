.is-home .fold#hero-target
  // margin-bottom: 0 !important
  // position: relative
  @apply mb-0 relative
  @apply xl:py-8 3xl:py-16

.hero
  &-img
    background: url("/assets/images/lucht-hero.jpg") 100% 100% no-repeat
    background-size: contain
    max-height: 600px

