.editor

  @apply text-black text-sm md:text-base
  .bg-blue &
    @apply text-white

  strong
    @apply font-medium

  a
    @apply uppercase tracking-widest font-bold
    @apply text-xs md:text-sm underline
    @apply text-pink hover:text-blue transition-colors


  &.editor-lines
    p,
    ol,
    ul
      @apply my-0 py-3
      @apply border-b-2 border-t-2 border-blue -mt-[2px]
      .bg-blue &
        border-white

    strong
      @apply inline-block mt-4 first:mt-0



  h1,
  h2
    @apply text-blue font-marist font-normal
    .bg-blue &
      @apply text-white

  h1
    @apply text-xl md:text-3xl
    @apply tracking-wide
    @apply mb-[1.5em]
    @apply first:mt-0

  h2
    @apply text-xl md:text-3xl
    @apply tracking-wide
    @apply my-[1.5em]
    @apply first:mt-0

  h3
    @apply text-pink text-lg md:text-xl font-semibold
    .bg-blue &
      @apply text-white
    @apply my-[1.5em]
    @apply first:mt-0

  h4
    @apply text-pink text-base md:text-lg font-semibold
    .bg-blue &
      @apply text-white
    @apply mt-[0.75em]
    @apply first:mt-0


  p,
  ol,
  ul
    @apply mb-[1em] max-w-[720px]
    &:last-child
      @apply mb-0

  ol,
  ul
    @apply max-w-3xl text-left

  ul
    li
      @apply pl-6 relative mb-2
      @apply before:content-['–'] before:absolute before:left-0


