.header

  @apply border-b-2 md:border-b-4
  border-image: linear-gradient(90deg, var(--c-blue), var(--c-pink)) 1

  path[data-name="logo-bubble-shrinked"],
  path[data-name="logo-bubble-full"]
    @apply transition-opacity duration-200

  path[data-name="logo-bubble-shrinked"]
    @apply opacity-0
  path[data-name="logo-bubble-full"]
    @apply opacity-100

  &.has-scrolled
    path[data-name="logo-bubble-shrinked"]
      @apply opacity-100
    path[data-name="logo-bubble-full"]
      @apply opacity-0
