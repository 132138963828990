.vita

  p,
  ul
    @apply my-2 md:my-3 first:my-0


  ul
    li
      @apply pl-6 relative
      @apply before:content-['–'] before:absolute before:left-0
