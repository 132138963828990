@import 'fonts';
@import 'properties';

@import 'tailwindcss/base';
@import 'custom-base.scss';

@import 'tailwindcss/components';
@import 'custom-components.scss';

@import 'tailwindcss/utilities';
@import 'custom-utilities.scss';

@import 'accordion';
@import 'base';
@import 'button';
@import 'contact';
@import 'editor';
@import 'footer';
// @import 'grid';
@import 'header';
@import 'hero';
@import 'img';
@import 'link';
@import 'navigation';
@import 'slider';
@import 'vita';
// @import 'tabs';


@import "./vendor/tiny-slider/tiny-slider.css";
