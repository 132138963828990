.ac-header
  @apply relative z-0

  .accordion-trigger-icon
    @apply absolute right-0 rotate-0 after:transition-transform

  .is-active &
    .accordion-trigger-icon
      @apply -rotate-180

  &:focus,
  &::-moz-focus-inner
    border: none
    outline: none !important

// .ac-header
//   .is-active &
//     @apply border-b-transparent

.ac-text
  @apply pb-8

.ac.is-active + .ac
  .ac-header
    @apply border-t-transparent
