.button
  @apply py-1.5 md:py-2 px-8 my-[1.5em]
  @apply inline-block text-center
  @apply text-xs md:text-sm text-white font-semibold uppercase tracking-widest
  @apply border-2 border-pink bg-pink rounded-full
  @apply transition-colors hover:bg-white hover:text-pink


.button,
.button-pager-link
  @apply transition-all duration-300 bg-pink
