.link

  &-pager-link
    @apply block w-[44px] h-[44px]
    @apply flex justify-center items-center


  &-arrow
    display: inline-flex
    align-items: center
    &:after
      content: ''
      display: inline-block
      width: 0.5em
      height: 0.5em
      border: 2px solid #fff
      border-bottom-color: rgb(255, 255, 255)
      border-bottom-style: solid
      border-bottom-width: 2px
      border-left-color: rgb(255, 255, 255)
      border-left-style: solid
      border-left-width: 2px
      border-left: 0
      border-bottom: 0
      transform: rotate(45deg)
      margin-left: 0.4em

    span
      @apply decoration-1 underline underline-offset-[0.3em] decoration-transparent transition-all

    &:hover
      span
        @apply decoration-inherit

.link

  &-expand
    @apply inline-flex items-center relative
    @apply pr-10
    .expand-toggle-label
      @apply decoration-1 underline underline-offset-[0.3em] decoration-transparent transition-all
    &:hover
      .expand-toggle-label
        @apply decoration-inherit

    .icon-expand-btn,
    .icon-expand-btn-hvr
      @apply w-[30px] h-[30px]
      @apply block absolute right-0 top-1/2 -translate-y-1/2 origin-center transition-all
      @apply rounded-full

    .icon-expand-btn-hvr
      @apply hidden


    &:hover,
    .is-active &
      .icon-expand-btn
        @apply hidden
      .icon-expand-btn-hvr
        @apply block

    .is-active &
      .icon-expand-btn-hvr
        @apply -rotate-180


.linkteaser
  .button
    @apply my-[1.5em]
