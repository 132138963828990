.tns-outer
  @apply block

.tns-ovh
  @apply overflow-visible


.tns-controls button
  @apply absolute z-10 top-1/2 -translate-y-1/2
  @apply w-14 h-14 xl:w-16 xl:h-16
  @apply overflow-hidden
  text-indent: -1111rem


  &[data-controls="prev"]
    @apply left-0 md:left-4 xl:left-8
    background: url("/assets/images/arrow-left.svg") no-repeat
    background-position: 50% 50%
    background-size: 50% 50%
    @screen md
      background-size: 100% 100%
    .bg-blue &
      background-image: url("/assets/images/arrow-left-white.svg")
  &[data-controls="next"]
    @apply right-0 md:right-4 xl:right-8
    background: url("/assets/images/arrow-right.svg") no-repeat
    background-position: 50% 50%
    background-size: 50% 50%
    @screen md
      background-size: 100% 100%
    .bg-blue &
      background-image: url("/assets/images/arrow-right-white.svg")


.slider--item
  @apply opacity-100
  @apply transition-opacity
  &[aria-hidden="true"]
    @apply opacity-25
